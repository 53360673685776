<template>
  <div>
    <NotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="time_table"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import { paginationRangeHandler } from '@components/BaseComponent/pagination.util.js'
import NotificationFilter from '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationFilters.vue'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import NOTIFICATION_PATHS from '@src/constants/notification-constants.js'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    NotificationFilter,
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.TIME_TABLE,
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        add_period_manager: {
          title: 'Reminder for Add Period Manager',
          label: 'Add Period Manager',
          feature: 'time_table',
          action: 'add_period_manager',
          loading: false,
          count: 0,
        },
        edit_period_manager: {
          title: 'Reminder for Edit Period Manager',
          label: 'Edit Period Manager',
          feature: 'time_table',
          action: 'edit_period_manager',
          loading: false,
          count: 0,
        },
        delete_period_manager: {
          title: 'Reminder for Delete Period Manager',
          label: 'Delete Period Manager',
          feature: 'time_table',
          action: 'delete_period_manager',
          loading: false,
          count: 0,
        },
        add_period: {
          title: 'Reminder for Add Period',
          label: 'Add Period',
          feature: 'time_table',
          action: 'add_period',
          loading: false,
          count: 0,
        },
        edit_period: {
          title: 'Reminder for Edit Period',
          label: 'Edit Period',
          feature: 'time_table',
          action: 'edit_period',
          loading: false,
          count: 0,
        },
        delete_period: {
          title: 'Reminder for Delete Period',
          label: 'Delete Period',
          feature: 'time_table',
          action: 'delete_period',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      this.notificationActions.add_period_manager.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
      this.notificationActions.edit_period_manager.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
      this.notificationActions.delete_period_manager.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
      this.notificationActions.add_period.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
      this.notificationActions.edit_period.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
      this.notificationActions.delete_period.optionsList = getNotificationsOptionsList([
        'concerned_teacher',
        'concerned_students_guardians',
        'concerned_students',
        'concerned_students_main_guardians',
      ])
    },

    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'PERIOD_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_PERIOD',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_period
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_PERIOD',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_period
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_PERIOD',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_period
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'PERIOD_MANAGER_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_PERIOD_MANAGER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_period_manager
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_PERIOD_MANAGER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_period_manager
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'DELETE_PERIOD_MANAGER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_period_manager
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
