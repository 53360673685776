<template>
  <!-- <ValidationObserver v-slot="{ invalid }" tag="div"> -->
  <TitleWrapper
    :filter-option="true"
    title="NOTIFS"
    tooltip-title="NOTIFS"
    :display-breadcrumb="true"
    :filters-ready="true"
    @clear="clearFilters"
    @applyFilter="applyFilters($event, invalid)"
  >
    <template v-slot:filterItems>
      <div class="flex flex-wrap w-full sm:pl-3 mb-10">
        <span class="mt-1 text-sm">Show disabled notifications</span>
        <div class="px-7 pt-0.5">
          <ToggleButton v-model="filtersPayload.disabled" class="mt-1" :is-status-hide="true" />
        </div>
      </div>
    </template>
  </TitleWrapper>
  <!-- </ValidationObserver> -->
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'

export default {
  components: {
    TitleWrapper,
    // ValidationObserver,
    ToggleButton,
  },
  emits: ['apply'],
  data() {
    return {
      filtersPayload: { disabled: false },
    }
  },
  methods: {
    applyFilters(event, invalid) {
      if (invalid) return
      this.$emit('apply', this.filtersPayload)
    },
    clearFilters() {
      this.filtersPayload.disabled = false
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
